import React, { Suspense, useContext } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle';
const Login = React.lazy(() => import('./views/login/index'));

const Layout =  React.lazy(() => import('./views/layout/index'));
const Dashboard =  React.lazy(() => import('./views/dashboard/index'));
const Patients =  React.lazy(() => import('./views/patients/index'));
const Investigationmaster =  React.lazy(() => import('./views/masters/investigationmaster/index'));
const UserRegistration = React.lazy(() => import('./views/userregistration/index'));
const isAuthenticated = () => {
  // Replace this with real authentication check logic
 // return Cookies.get('isAuthenticated') === "true";
 return true;
};
const PrivateRoute = ({ element, path }) => {
  return isAuthenticated() ? element : <Navigate to="/" />;
};
function App() {
  return (
    <Router>
      <Suspense fallback={<div>Loading...</div>}>
        <Routes>
          <Route path="/" element={<Login />} />
          
          {/* Admin routes */}
          <Route path="/" element={<PrivateRoute element={<Layout />} />}>
            <Route path="user-registration" element={<UserRegistration />} />
            <Route path="patients" element={<Patients />} />
            <Route path="investigation-master" element={<Investigationmaster />} />
            
            
          </Route>
        </Routes>
      </Suspense>
    </Router>
  );
}

export default App;
